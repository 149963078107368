/**
 * @Author: siyukok
 * @Date: 2022/2/10 2:21 PM
 * @Version 1.0
 */
export const KEY_ACCESS_TOKEN = 'KEY_ACCESS_TOKEN'
export const KEY_ACCESS_TOKEN_EXPIRE = 'KEY_ACCESS_TOKEN_EXPIRE'
export const KEY_ACCESS_REFRESH_TOKEN = 'KEY_ACCESS_REFRESH_TOKEN'
export const KEY_ACCESS_USER_INFO = 'KEY_ACCESS_USER_INFO'
export const KEY_CORP_ID = 'KEY_CORP_ID'
export const KEY_TOTAL = 'KEY_TOTAL'
