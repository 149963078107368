





























































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import Breadcrumb from '@/components/Breadcrumb/index.vue'
import Hamburger from '@/components/Hamburger/index.vue'
import UserService from '@/services/user'
import config from '@/../package.json'
import fetchExchangeQuery from '@/api/husuraApi/getExchangeOrderSummaryApi'
import UserStorage from '@/storages/user'

@Component({
  name: 'Navbar',
  components: {
  Breadcrumb,
  Hamburger
  }
  })
export default class extends Vue {
  private total = ''

  created() {
    this.fetchCenterQuery()
  }

  get sidebar() {
    return AppModule.sidebar
  }

  get device() {
    return AppModule.device.toString()
  }

  get userInfo() {
    return UserStorage.userInfo
  }

  get avatar() {
    return UserModule.avatar
  }

  // get total() {
  //   return AppModule.total.keyTotal
  // }

  get version() {
    return config.version
  }

  private toggleSideBar() {
    AppModule.ToggleSideBar(false)
  }

  private async logout() {
    await UserService.logout()
    await this.$router.push(`/login?redirect=${this.$route.fullPath}`)
  }

  private switchButton() {
    console.log('111')
  }

  private fetchCenterQuery(refresh = true) {
    const token = UserModule.oauth.accessToken
    const jwt = UserService.decodeToken(token)
    let userDID
    if (jwt) {
      userDID = jwt.sub
    }
    if (!userDID) {
      this.$message.warning('未获取到DID信息，请重新登录')
      return
    }
    fetchExchangeQuery()
      .then(({ data: res }) => {
        const { data } = res
        this.total = data.exchange_order_summary.length
      })
      .catch(error => {
        console.error(error)
      })
  }
}
