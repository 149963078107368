/**
 * @Author: siyukok
 * @Date: 2022/3/3 10:24 AM
 * @Version 1.0
 */
export default {
  login: {
    login: '退出登录'
  },
  center: {
    RULEMATCH: 'RULEMATCH',
    Total_Exchange_Volume: '交易所总交易量',
    Search: '搜索',
    Inspection_State_Filter: '选择交易稽查状态',
    Exchange_Risk_Index: '交易风险指数',
    Start_Score: '起始分数',
    End_Score: '截止分数',
    Exchange_Date: '交易日期',
    Start_Date: '开始日期',
    End_Date: '结束日期',
    Search_Exchange: '查询交易信息',
    Exchange_Depository_Hash: '交易存证哈希',
    To_Be_Inspect: '未稽查',
    Inspection_Passed: '稽查通过',
    Inspection_Failed: '稽查未通过',
    Begin_Inspection: '开始交易稽查',
    View_Inspection_Result: '交易稽查结果',
    RWA_Token_ID: 'RWA Token ID',
    RWA_Name: 'RWA 名称',
    RWA_Type: 'RWA 类型',
    RWA_Issuer: 'RWA 颁发者',
    Seller: '卖家',
    Purchaser: '买家',
    Exchange_Time: '交易时间',
    Exchange_Inspection_State: '交易稽查状态',
    Exchange_Inspection: '交易稽查',
    Underlying_Assets_Inspecting: '底层资产稽查中…',
    Underlying_Assets_Inspecting_Passed: '底层资产稽查通过',
    Underlying_Assets_Inspecting_Failed: '底层资产稽查未通过',
    Underlying_Assets_Inspecting_Started: '底层资产稽查开始',
    Underlying_Assets_Inspecting_Finished: '底层资产稽查结束',
    Assets_Authentication_Inspecting: '资产确权稽查中…',
    Assets_Authentication_Inspecting_Started: '资产确权稽查开始',
    Assets_Authentication_Inspecting_Finished: '资产确权稽查结束',
    Assets_Authentication_Inspecting_Passed: '资产确权稽查通过',
    Assets_Authentication_Inspecting_Failed: '资产确权稽查未通过',
    Owner_Authenticated: '业主已确权',
    Assets_Development_Organization_Authenticated: '资产托管机构已确权',
    Accounting_Firm_Authenticated: '会计师事务所已确权',
    Low_Firm_Authenticated: '律师事务所已确权',
    Licensed_Financial_Institution_Authenticated: '持牌金融机构已确权',
    Depository_Information_Inspecting: '存证信息稽查中...',
    Depository_Information_Inspecting_Passed: '存证信息稽查通过',
    Depository_Information_Inspecting_Failed: '存证信息稽查未通过',
    Depository_Information_Inspecting_Started: '存证信息稽查开始',
    Depository_Information_Inspecting_Finished: '存证信息稽查完毕',
    Exchange_Order_Signature: '交易订单签名',
    Asserts_Transferring_Hash: '资产转移哈希',
    Payment_Hash: '支付哈希',
    Settlement_Hash: '结算哈希',
    Seller_Information_Inspecting: '卖家信息稽查中...',
    Seller_Information_Inspection_Passed: '卖家信息稽查通过',
    Seller_Information_Inspection_Failed: '卖家信息稽查未通过',
    Seller_Information_Inspection_Started: '卖家信息稽查开始',
    Seller_Information_Inspection_Finished: '卖家信息稽查完毕',
    Seller_Address: '卖家地址',
    Seller_Identity: '卖家身份',
    Seller_AML_Verification: '卖家AML验证',
    AML_CTF_List_Checked: '已核对AML/CTF名单',
    Name: '姓名',
    Sex: '性别',
    Date_of_birth: '出生年月',
    Exchange_Information_Inspecting: '交易信息稽查中...',
    Exchange_Information_Inspecting_Passed: '交易信息稽查通过',
    Exchange_Information_Inspecting_Failed: '交易信息稽查未通过',
    Exchange_Information_Inspecting_Started: '交易信息稽查开始',
    Exchange_Information_Inspecting_Finished: '交易信息稽查完毕',
    Exchange: '交易所',
    RWA_Blockchain: 'RWA 区块链',
    Transaction_Amount: '成交金额',
    Transaction_Currency: '成交货币',
    Currency_Issuer: '货币发行人',
    Purchaser_Information_Inspecting: '买家信息稽查中...',
    Purchaser_Information_Inspection_Passed: '买家信息稽查通过',
    Purchaser_Information_Inspection_Failed: '买家信息稽查未通过',
    Purchaser_Information_Inspection_Started: '买家信息稽查开始',
    Purchaser_Information_Inspection_Finished: '买家信息稽查完毕',
    Purchaser_Address: '买家地址',
    Purchaser_Identity: '买家身份',
    Purchaser_AML_Verification: '买家AML验证'
  }
}
