/**
 * @Author: siyukok
 * @Date: 2022/3/3 10:24 AM
 * @Version 1.0
 */
export default {
  login: {
    login: 'Login Out'
  },
  center: {
    RULEMATCH: 'RULEMATCH',
    Total_Exchange_Volume: 'Total Exchange Volume',
    Search: 'Search',
    Inspection_State_Filter: 'Inspection State Filter',
    Exchange_Risk_Index: 'Exchange Risk Index',
    Start_Score: 'Start Score',
    End_Score: ' End Score',
    Exchange_Date: 'Exchange Date',
    Start_Date: 'Start Date',
    End_Date: 'End Date',
    Search_Exchange: 'Search Exchange',
    Exchange_Depository_Hash: 'Exchange Depository Hash',
    To_Be_Inspect: 'To Be Inspect',
    Inspection_Passed: 'Inspection Passed',
    Inspection_Failed: 'Inspection Failed',
    Begin_Inspection: 'Begin Inspection',
    View_Inspection_Result: 'View Inspection Result',
    RWA_Token_ID: 'RWA Token ID',
    RWA_Name: 'RWA Name',
    RWA_Type: 'RWA Type',
    RWA_Issuer: 'RWA Issuer',
    Seller: 'Seller',
    Purchaser: 'Purchaser',
    Exchange_Time: 'Exchange Time',
    Exchange_Inspection_State: 'Exchange Inspection State',
    Exchange_Inspection: 'Exchange Inspection',
    Underlying_Assets_Inspecting: 'Underlying Assets Inspecting…',
    Underlying_Assets_Inspecting_Passed: 'Underlying Assets Inspection Passed',
    Underlying_Assets_Inspecting_Failed: 'Underlying Assets Inspection Failed',
    Underlying_Assets_Inspecting_Started: 'Underlying Assets Inspection Started',
    Underlying_Assets_Inspecting_Finished: 'Underlying Assets Inspection Finished',
    Assets_Authentication_Inspecting: 'Assets Authentication Inspecting…',
    Assets_Authentication_Inspecting_Started: 'Assets Authentication Inspection Started',
    Assets_Authentication_Inspecting_Finished: 'Assets Authentication Inspection Finished',
    Assets_Authentication_Inspecting_Passed: 'Assets Authentication Inspection Passed',
    Assets_Authentication_Inspecting_Failed: 'Assets Authentication Inspection Failed',
    Owner_Authenticated: 'Owner Authenticated',
    Assets_Development_Organization_Authenticated: 'Assets Development Organization Authenticated',
    Accounting_Firm_Authenticated: 'Accounting Firm Authenticated',
    Low_Firm_Authenticated: 'Low Firm Authenticated',
    Licensed_Financial_Institution_Authenticated: 'Licensed Financial Institution Authenticated',
    Depository_Information_Inspecting: 'Depository Information Inspecting...',
    Depository_Information_Inspecting_Passed: 'Depository Information Inspection Passed',
    Depository_Information_Inspecting_Failed: 'Depository Information Inspection Failed',
    Depository_Information_Inspecting_Started: 'Depository Information Inspection Started',
    Depository_Information_Inspecting_Finished: 'Depository Information Inspection Finished',
    Exchange_Order_Signature: 'Exchange Order Signature',
    Asserts_Transferring_Hash: 'Asserts Transferring Hash',
    Payment_Hash: 'Payment Hash',
    Settlement_Hash: 'Settlement Hash',
    Seller_Information_Inspecting: 'Seller Information Inspecting...',
    Seller_Information_Inspection_Passed: 'Seller Information Inspection Passed',
    Seller_Information_Inspection_Failed: 'Seller Information Inspection Failed',
    Seller_Information_Inspection_Started: 'Seller Information Inspection Started',
    Seller_Information_Inspection_Finished: 'Seller Information Inspection Finished',
    Seller_Address: 'Seller Address',
    Seller_Identity: 'Seller Identity',
    Seller_AML_Verification: 'Seller AML Verification',
    AML_CTF_List_Checked: 'AML/CTF List Checked',
    Name: 'Name',
    Sex: 'Sex',
    Date_of_birth: 'Date of birth',
    Exchange_Information_Inspecting: 'Exchange Information Inspecting...',
    Exchange_Information_Inspecting_Passed: 'Exchange Information Inspection Passed',
    Exchange_Information_Inspecting_Failed: 'Exchange Information Inspection Failed',
    Exchange_Information_Inspecting_Started: 'Exchange Information Inspection Started',
    Exchange_Information_Inspecting_Finished: 'Exchange Information Inspection Finished',
    Exchange: 'Exchange',
    RWA_Blockchain: 'RWA Blockchain',
    Transaction_Amount: 'Transaction Amount',
    Transaction_Currency: 'Transaction Currency',
    Currency_Issuer: 'Currency Issuer',
    Purchaser_Information_Inspecting: 'Purchaser Information Inspecting...',
    Purchaser_Information_Inspection_Passed: 'Purchaser Information Inspection Passed',
    Purchaser_Information_Inspection_Failed: 'Purchaser Information Inspection Failed',
    Purchaser_Information_Inspection_Started: 'Purchaser Information Inspection Started',
    Purchaser_Information_Inspection_Finished: 'Purchaser Information Inspection Finished',
    Purchaser_Address: 'Purchaser Address',
    Purchaser_Identity: 'Purchaser Identity',
    Purchaser_AML_Verification: 'Purchaser AML Verification'
  }
}
